<template>
  <div>

    <!-- FORM -->
    <BOverlay
      :show="configs.loading"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow class="ml-25 mr-25 mb-1">

        <!-- ESTUDIANTE COL IZQ -->
        <BCol cols="6">
          <BRow>

            <!-- RUT / IPE -->
            <BCol cols="12">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                :label-for="estudiante.tipo_identificacion === 1 ? 'rut' : 'ipe'"
              >
                <template #label>
                  {{ estudiante.tipo_identificacion === 1 ? 'RUT' : 'IPE' }} <span class="text-danger">*</span>
                </template>
                <BRow>

                  <!-- RUT -->
                  <BCol :hidden="estudiante.tipo_identificacion === 2" cols="8">
                    <BFormInput
                      id="rut"
                      v-model="estudiante.rut"
                      placeholder="Ej: 26.636.993-1"
                      :state="v$.estudiante.rut.$error === true
                      ? false
                      : null"
                      :autofocus="crud === 'c'"
                      :disabled="crud === 'u'"
                      @blur="buscarRuts()"
                      @keyup="estudiante.rut = formatRut(estudiante.rut)"
                    />
                    <!-- Validaciones -->
                    <BFormInvalidFeedback
                      v-if="v$.estudiante.rut.$error"
                      id="rutInfo"
                      class="pb-0"
                    >
                      <p
                        v-for="error of v$.estudiante.rut.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </BFormInvalidFeedback>
                  </BCol>
                  
                  <!-- IPE -->
                  <BCol v-if="estudiante.tipo_identificacion === 2" cols="8">
                    <BFormInput
                      id="ipe"
                      v-model="estudiante.ipe"
                      placeholder="Ej: 01122000"
                      :state="v$.estudiante.ipe.$error === true
                      ? false
                      : null"
                      :disabled="crud === 'u'"
                      @keyup="estudiante.ipe = formatRut(estudiante.ipe)"
                    />
                    <!-- Validaciones -->
                    <BFormInvalidFeedback
                      v-if="v$.estudiante.ipe.$error"
                      id="rutInfo"
                      class="pb-0"
                    >
                      <p
                        v-for="error of v$.estudiante.ipe.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </BFormInvalidFeedback>
                  </BCol>
                  
                  <!-- TIPO IDENTIFICACION -->
                  <BCol id="colTipo_identificacion" cols="4" class="pl-0">
                    <BFormSelect
                      id="tipo-identificacion-select"
                      v-model="estudiante.tipo_identificacion"
                      :options="optionsTipoIdentificacion"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      class="custom-select"
                      :class="v$.estudiante.tipo_identificacion.$error === true 
                        ? 'border-danger rounded' 
                        : ''"
                      @input="changeTipoIdentificacion"
                      :disabled="crud === 'u'"
                    />
                    <div
                      v-if="v$.estudiante.tipo_identificacion.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.estudiante.tipo_identificacion.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </BCol>

                </BRow>
              </BFormGroup>
            </BCol>

            <!-- NOMBRE -->
            <BCol cols="12">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="nombre"
              >
                <template #label>
                  Nombre <span class="text-danger">*</span>
                </template>
                <BFormInput
                  id="nombre"
                  v-model="estudiante.nombre"
                  placeholder="Ingrese el nombre"
                  :state="v$.estudiante.nombre.$error === true
                  ? false
                  : null"
                />
                <!-- Validaciones -->
                <BFormInvalidFeedback
                  v-if="v$.estudiante.nombre.$error"
                  id="nombreInfo"
                >
                  <p
                    v-for="error of v$.estudiante.nombre.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </BFormInvalidFeedback>
              </BFormGroup>
            </BCol>

            <!-- SEGUNDO NOMBRE -->
            <BCol cols="12">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="segundo_nombre"
              >
                <template #label>
                  Segundo nombre
                </template>
                <BFormInput
                  id="segundo_nombre"
                  v-model="estudiante.segundo_nombre"
                  placeholder="Ingrese el segundo nombre"
                  :state="v$.estudiante.segundo_nombre.$error === true
                  ? false
                  : null"
                />
                <!-- Validaciones -->
                <BFormInvalidFeedback
                  v-if="v$.estudiante.segundo_nombre.$error"
                  id="segundo_nombreInfo"
                >
                  <p
                    v-for="error of v$.estudiante.segundo_nombre.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </BFormInvalidFeedback>
              </BFormGroup>
            </BCol>

            <!-- PRIMER APELLIDO -->
            <BCol cols="12" class="text-bold">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="primer_apellido"
              >
                <template #label>
                  Apellido paterno <span class="text-danger">*</span>
                </template>
                <BFormInput
                  id="primer_apellido"
                  v-model="estudiante.primer_apellido"
                  placeholder="Ingrese el apellido paterno"
                  :state="v$.estudiante.primer_apellido.$error === true
                  ? false
                  : null"
                />
                <!-- Validaciones -->
                <BFormInvalidFeedback
                  v-if="v$.estudiante.primer_apellido.$error"
                  id="primer_apellidoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.primer_apellido.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </BFormInvalidFeedback>
              </BFormGroup>
            </BCol>

            <!-- SEGUNDO APELLIDO -->
            <BCol cols="12">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="segundo_apellido"
              >
                <template #label>
                  Apellido materno <span class="text-danger">*</span>
                </template>
                <BFormInput
                  id="segundo_apellido"
                  v-model="estudiante.segundo_apellido"
                  placeholder="Ingrese el apellido materno"
                  :state="v$.estudiante.segundo_apellido.$error === true
                  ? false
                  : null"
                />
                <!-- Validaciones -->
                <BFormInvalidFeedback
                  v-if="v$.estudiante.segundo_apellido.$error"
                  id="segundo_apellidoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.segundo_apellido.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </BFormInvalidFeedback>
              </BFormGroup>
            </BCol>

            <!-- FECHA DE NACIMIENTO -->
            <BCol cols="12">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="fecha_nacimiento"
              >
                <template #label>
                  Fecha nacimiento <span class="text-danger">*</span>
                </template>

                <flat-pickr
                  v-model="estudiante.fecha_nacimiento"
                  class="form-control"
                  :config="config.flatPickrFechaNacimiento"
                  v-mask="'##-##-####'"
                  placeholder="dd-mm-YYYY"
                  autocomplete="off"
                  name="date"
                  :class="v$.estudiante.fecha_nacimiento.$error === true
                    ? 'form-control border-danger rounded'
                    : 'form-control'"
                />
                <div
                  id="asistentesInfo"
                  v-if="v$.estudiante.fecha_nacimiento.$error"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.fecha_nacimiento.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </BFormGroup>
            </BCol>

            <!-- GENERO -->
            <BCol cols="12">
              <BFormGroup
                id="group-genero"
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="genero"
              >
                <template #label>
                  Genero <span class="text-danger">*</span>
                </template>
                
                <v-select
                  id="genero"
                  v-model="estudiante.genero"
                  placeholder="Seleccione un genero"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="option => option.genero"
                  :options="optionsGeneros"
                  :class="v$.estudiante.genero.$error === true
                    ? 'border-danger rounded'
                    : ''"
                />
                  <!-- :disabled="optionsGeneros.length === 0" -->
                <div
                  v-if="v$.estudiante.genero.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.genero.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </BFormGroup>
            </BCol>
            
          </BRow>
        </BCol>

        <!-- COL CORREO -->
        <BCol cols="6">
          <BRow>

            <!-- CORREO -->
            <BCol cols="12">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="correo"
              >
                <template #label>
                  Correo <span class="text-danger">*</span>
                </template>
                <BInputGroup
                  :class="v$.estudiante.correo.$error === false
                    ? ''
                    : 'is-invalid'"
                >
                  <BInputGroupPrepend is-text>
                    <feather-icon icon="MailIcon" />
                  </BInputGroupPrepend>
                  <BFormInput
                    id="correo"
                    v-model="estudiante.correo"
                    placeholder="Ingrese el correo electrónico"
                    :class="v$.estudiante.correo.$error === false
                      ? ''
                      : 'border-danger rounded-right'"
                    :state="v$.estudiante.correo.$error === true
                    ? false
                    : null"
                  />
                </BInputGroup>
                <!-- Validaciones -->
                <BFormInvalidFeedback
                  v-if="v$.estudiante.correo.$error"
                  id="correoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.correo.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </BFormInvalidFeedback>
              </BFormGroup>
            </BCol>

            <!-- CELULAR -->
            <BCol
            cols="12"
            >
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="celular"
              >
                <template #label>
                  Celular <span class="text-danger">*</span>
                </template>
                <BInputGroup
                  :class="v$.estudiante.celular.$error === false ? '' : 'is-invalid'"
                >
                  <BInputGroupPrepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </BInputGroupPrepend>
                  <BFormInput
                    id="celular"
                    v-model="estudiante.celular"
                    placeholder="Ingrese el número de celular"
                    :state="v$.estudiante.celular.$error === true
                    ? false
                    : null"
                    @keyup='estudiante.celular = formatSoloNumerosMaxLenght(estudiante.celular, 11)'
                  />
                </BInputGroup>
                <!-- Validaciones -->
                <BFormInvalidFeedback
                  v-if="v$.estudiante.celular.$error"
                  id="celularInfo"
                >
                  <p
                    v-for="error of v$.estudiante.celular.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </BFormInvalidFeedback>
              </BFormGroup>
            </BCol>

            <!-- CURSO -->
            <BCol cols="12" >
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="id_cursos"
              >
                <template #label>
                  Curso <span class="text-danger">*</span>
                </template>
                  <v-select
                    v-if="configs.loadingCursos"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Cargando cursos..."
                    :disabled="true"
                  />
                  <v-select
                    v-else
                    v-model="estudiante.id_cursos"
                    placeholder="Seleccione el curso..."
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :reduce="option => option.id_cursos"
                    :options="optionsCursos"
                    :class="v$.estudiante.id_cursos.$error === true
                      ? 'border-danger rounded'
                      : ''"
                    :disabled="crud === 'u'"
                    @input="changeTipoIdentificacion()"
                  />
                <div
                  v-if="v$.estudiante.id_cursos.$error"
                  id="asistentesInfo"
                  class="text-danger text-left"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.estudiante.id_cursos.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </BFormGroup>
            </BCol>
            
            <!-- FECHA INGRESO -->
            <BCol cols="12" >
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label-for="fecha_ingreso"
              >
                <template #label>
                  Fecha ingreso
                </template>
                <flat-pickr
                  v-model="estudiante.fecha_ingreso"
                  class="form-control"
                  :config="config.flatPickrFechaIngreso"
                  v-mask="'##-##-####'"
                  placeholder="dd-mm-YYYY"
                  autocomplete="off"
                  name="date"
                  :class="v$.estudiante.fecha_ingreso.$error === true
                    ? 'form-control border-danger rounded'
                    : 'form-control'"
                />
                <!-- Validaciones -->
                <BFormInvalidFeedback
                  v-if="v$.estudiante.fecha_ingreso.$error"
                  id="fecha_ingresoInfo"
                >
                  <p
                    v-for="error of v$.estudiante.fecha_ingreso.$errors"
                    :key="error.$uid"
                  >
                    {{ error.$message }}
                  </p>
                </BFormInvalidFeedback>
              </BFormGroup>
            </BCol>

            <!-- PIE -->
            <BCol cols="12">
              <BFormGroup
                content-cols="9"
                label-class="font-weight-bold mt-25 text-right"
                label="¿Estudiante PIE?"
                label-for="pie"
              >
                <BFormCheckbox
                  checked="true"
                  v-model="estudiante.pie"
                  class="custom-control-success mt-50 text-left"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                </BFormCheckbox>
              </BFormGroup>
            </BCol>
          </BRow>
        </BCol>

      </BRow>

      <BTabs class="border rounded pt-50 pb-2" >
        <!-- Direccion -->
        <BTab lazy active>
          <template #title>
            <feather-icon
              icon="MapPinIcon"
              size="16"
              class="mr-0 mr-sm-50"
              :class="{'text-danger': hasDireccionErrors}"
            />
            <span 
              class="d-none d-sm-inline"
              :class="{'text-danger': hasDireccionErrors}"
            >
              Dirección
            </span>
          </template>

          <colLinea style="margin-top: -15px !important;"/>
          
          <BRow class="mt-3">

            <!-- DIRECCIÓN COL IZQ -->
            <BCol cols="6">
              <BRow>

                <!-- COMUNA -->
                <BCol cols="12">
                  <BFormGroup
                    label-class="font-weight-bold mt-25 text-right"
                    content-cols="9"
                    label="Comuna *"
                    label-for="id_comuna"
                  >
                    <template #label>
                      Comuna <span class="text-danger">*</span>
                    </template>
                    <v-select
                      v-if="configs.loadingComunas"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      placeholder="Cargando comunas..."
                      :disabled="true"
                    />
                    <v-select
                      v-else
                      v-model="estudiante.id_comuna"
                      placeholder="Seleccione una comuna..."
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nombre"
                      :reduce="option => option.id_comuna"
                      :options="optionsComunas"
                      :class="v$.estudiante.id_comuna.$error === true
                        ? 'border-danger rounded'
                        : ''"
                      :disabled="optionsComunas.length === 0"
                    />
                    <div
                      v-if="v$.estudiante.id_comuna.$error"
                      id="asistentesInfo"
                      class="text-danger text-left"
                      style="font-size: 0.857rem;"
                    >
                      <p v-for="error of v$.estudiante.id_comuna.$errors" :key="error.$uid">
                        {{ error.$message }}
                      </p>
                    </div>
                  </BFormGroup>
                </BCol>

                <!-- CALLE -->
                <BCol cols="12">
                  <BFormGroup
                    label-class="font-weight-bold mt-25 text-right"
                    content-cols="9"
                    label-for="nombre_calle"
                  >
                    <template #label>
                      Calle <span class="text-danger">*</span>
                    </template>
                    <BFormInput
                      id="nombre_calle"
                      v-model="estudiante.nombre_calle"
                      placeholder="Ingrese el nombre de la calle"
                      :state="v$.estudiante.nombre_calle.$error === true
                      ? false
                      : null"
                    />
                    <!-- Validaciones -->
                    <BFormInvalidFeedback
                      v-if="v$.estudiante.nombre_calle.$error"
                      id="nombre_calleInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.nombre_calle.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </BFormInvalidFeedback>
                  </BFormGroup>
                </BCol>

              </BRow>
            </BCol>
            <BCol cols="6">
              <BRow>
                
                <!-- NUMERO -->
                <BCol cols="12">
                  <BFormGroup
                    label-class="font-weight-bold mt-25 text-right"
                    content-cols="9"
                    label-for="numero"
                  >
                    <template #label>
                      Número <span class="text-danger">*</span>
                    </template>
                    <BInputGroup
                      :class="v$.estudiante.numero.$error === false
                        ? ''
                        : 'is-invalid'"
                    >
                      <BInputGroupPrepend is-text>
                        #
                      </BInputGroupPrepend>
                      <BFormInput
                        id="numero"
                        v-model="estudiante.numero"
                        placeholder="Ingrese el número de la calle"
                        :state="v$.estudiante.numero.$error === true
                        ? false
                        : null"
                        @keyup='estudiante.numero = formatSoloNumerosMaxLenght(estudiante.numero, 8)'
                      />
                    </BInputGroup>
                    <!-- Validaciones -->
                    <BFormInvalidFeedback
                      v-if="v$.estudiante.numero.$error"
                      id="numeroInfo"
                    >
                      <p
                        v-for="error of v$.estudiante.numero.$errors"
                        :key="error.$uid"
                      >
                        {{ error.$message }}
                      </p>
                    </BFormInvalidFeedback>
                  </BFormGroup>
                </BCol>

              </BRow>
            </BCol>
          </BRow>
        </BTab>

        <!-- Datos escolares -->
        <BTab lazy>
          <template #title>
            <feather-icon
              icon="ToggleLeftIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Información Escolar</span>
          </template>
          
          <colLinea style="margin-top: -15px !important;" />
          
          <TabEscolares
            class="mt-3"
            crud="u"
            :matricula="estudiante"
          />
        </BTab>
      </BTabs>

    </BOverlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BInputGroup, BFormSelect,
  BFormInvalidFeedback, BOverlay, BTabs, BTab, BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { VueMaskDirective } from 'v-mask';

import flatPickr from 'vue-flatpickr-component'

import { mapActions, mapGetters, mapState } from 'vuex'

// MIXINS
import { rut } from '@/mixins/rut'
import { formatos } from '@/mixins/formatos'

// VALIDACIONES //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'


// Componentes reciclados
import colLinea from '@/views/components/Form/colLinea.vue';
import btnSubmit from '@/views/components/Form/btnSubmit.vue';

import TabEscolares from './TabEstudiante/TabEscolares.vue'

export default {
  directives: {
    mask: VueMaskDirective,
  },
  components: {
    BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BInputGroup, BFormSelect,
    BFormInvalidFeedback, BOverlay, BTabs, BTab, BInputGroupPrepend,
    vSelect,
    flatPickr,

    // Componentes reciclados
    colLinea,
    btnSubmit,

    TabEscolares,
  },
  mixins: [rut, formatos],
  data() {
    return {
      // data
      configs: {
        loading: true,
        loadingComunas: true,
        loadingCursos: true,
      },
      estudiante: [],
      // otros
      config: {
        flatPickrFechaNacimiento: {
          locale: 'es',
          allowInput: true,
          enableTime: false,
          dateFormat: 'd-m-Y',
          maxDate: new Date(),
        },
        flatPickrFechaIngreso: {
          locale: 'es',
          allowInput: true,
          enableTime: false,
          dateFormat: 'd-m-Y',
          maxDate: new Date(),
          disable: [
            function(date) {
              // Deshabilita todos los domingos y sábado
              return date.getDay() === 0;
              // return date.getDay() === 0 || date.getDay() === 6;
            }
          ],
        },
      },
      // options
      optionsComunas: [],
      optionsPais: [
        {
          id_pais: 45,
          nombre: 'Chile',
        }
      ],
      optionsCursos: [],
    }
  },
  props: {
    matricula: {
      type: Object,
      required: true,
    },
    crud: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getComunas: 'ceds/getComunas',
      getCursos: 'cursos/getCursos',
    }),
    ...mapState({
      optionsTipoIdentificacion: state => state.tiposIdentificaciones.tiposIdentificaciones,
      optionsGeneros: state => state.generos.generos
    }),
    hasDireccionErrors() {
      return this.v$.estudiante.id_comuna.$error || this.v$.estudiante.nombre_calle.$error || this.v$.estudiante.numero.$error;
    },
  },
  // watch: {
  //   matricula() {
  //     // this.initialize()
  //   },
  // },
  mounted() {
    this.initialize()
    this.cargaComunas()
    this.cargaCursos()
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt',
      fetchComunas: 'ceds/fetchComunas',
      fetchCursosEstablecimiento: 'cursos/fetchCursosEstablecimiento',
      fetchPersonaRut: 'personas/fetchPersonaRut',
      fetchMatricula: 'matriculas/fetchMatricula',
      addMatricula: 'matriculas/addMatricula',
    }),
    setEstudianteData() {
      return {
            tipo_identificacion: 1,
            rut: this.estudiante.rut,
            nombre: '',
            segundo_nombre: '',
            primer_apellido: '',
            segundo_apellido: '',
            genero: null,
            fecha_nacimiento: '',
            correo: '',
            celular: '',
            id_comuna: null,
            nombre_calle: '',
            numero: null,
            fecha_ingreso: this.formatoFechaHoyDiaMesAno(),
            id_pais: 45,
            id_cursos: null,
            procedencia: 27,
            repitencia: false,
            prioritario: false,
            beneficio: false,
            pie: false,
            religion: false,
            junaeb: false,
            autorizacion: false,
            aprendizaje: false,
            transporte: false,
          }
    },
    async initialize() {
      this.configs.loading = true
      if (this.crud === 'u') {
        this.cargarEstudiante()
      } else {
        this.matricula.estudiante.rut = ''
        this.estudiante = this.matricula.estudiante
      }
      this.configs.loading = false
    },
    async cargaComunas() {
      this.configs.loadingComunas = true
      await this.fetchComunas()
      this.optionsComunas = []
      this.getComunas.forEach(comuna => {
        this.optionsComunas.push({
          id_comuna: comuna.RefCountyId,
          nombre: comuna.Description,
        })
      })
      this.configs.loadingComunas = false
    },
    async cargaCursos() {
      this.configs.loadingCursos = true
      await this.fetchCursosEstablecimiento(this.getUser.id_establecimiento)
      this.optionsCursos = []
      this.getCursos.forEach(curso => {
        const nombre = curso.nombre+' '+ curso.letra
        this.optionsCursos.push({
          id_cursos: curso.id,
          title: nombre,
        })
      })
      this.configs.loadingCursos = false
    },
    async buscarRuts() {
      if (this.estudiante.rut !== '') {
        this.configs.loading = true
        const rutDividido = this.divideRut(this.estudiante.rut)
        const params = {
          rut: rutDividido.rutSinDv,
          dv: rutDividido.dv,
          rol: 10,
        }
        const response = await this.fetchPersonaRut(params)
        if (!response.status && response) {
          const { rut, ...restoDeDatos } = response;
          this.estudiante = { 
            ...this.estudiante, 
            ...restoDeDatos,
            fecha_ingreso: this.formatoFechaHoyDiaMesAno(),
          }; 
        } else {
          this.estudiante = this.setEstudianteData();
        }
        this.configs.loading = false
      }
    },
    cargarEstudiante() {
      const rut = typeof this.matricula.rut_completo === 'undefined'
        ? this.formatRut(this.matricula.rut+'-'+this.matricula.dv)
        : this.matricula.rut_completo

      const comuna = this.getComunas.find(c => c.RefCountyId === this.matricula.id_comuna)
      const id_comuna = typeof comuna === 'undefined' ? null : this.matricula.id_comuna
      
      this.estudiante = {
        ...this.matricula,
        rut,
        fecha_nacimiento: this.formatoDiaMesYear(this.matricula.fecha_nacimiento),
        id_comuna,
        fecha_ingreso: this.formatoDiaMesYear(this.matricula.fecha_ingreso),
        repitencia: this.matricula.repitencia === 1,
        prioritario: this.matricula.prioritario === 1,
        beneficio: this.matricula.beneficio === 1,
        pie: this.matricula.pie === 1,
        religion: this.matricula.religion === 1,
        junaeb: this.matricula.junaeb === 1,
        autorizacion: this.matricula.autorizacion === 1,
        aprendizaje: this.matricula.aprendizaje === 1,
        transporte: this.matricula.transporte === 1,
      }
    },

    changeTipoIdentificacion() {
      if (this.estudiante.tipo_identificacion === null) {
        this.estudiante.tipo_identificacion = 1
      }
    },

    // SON LLAMADOS DESDE EL PADRE
    validarFormulario() {
      this.v$.estudiante.$touch()
      return !this.v$.estudiante.$invalid;
    },
    getEstudiante() {
      const rutDividido = this.divideRut(this.estudiante.rut)
      return {
        ...this.estudiante,
        rut: rutDividido.rutSinDv,
        dv: rutDividido.dv,
        id_rol: 10,
        id_establecimiento: this.getUser.id_establecimiento,
      };
    },
    resetValidations() {
      this.v$.$reset(); 
    },
  },
  validations() {
    let rut_estudiante = new Object()
    let ipe_estudiante = new Object()

    if (this.estudiante.tipo_identificacion === 1) {
      rut_estudiante = {
        required: helpers.withMessage('El campo es requerido.', required),
        maxLength: helpers.withMessage('Debe tener máximo 13 caracteres.', maxLength(13)),
      }
    } else if (this.estudiante.tipo_identificacion === 2) {
      ipe_estudiante = {
        required: helpers.withMessage('El campo es requerido.', required),
        maxLength: helpers.withMessage('Debe tener máximo 8 caracteres.', maxLength(8)),
      }
    }

    return {
      estudiante: {
        rut: rut_estudiante,
        ipe: ipe_estudiante,
        nombre: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        segundo_nombre: {
          maxLength: helpers.withMessage('Debe tener máximo 150 caracteres.', maxLength(150)),
        },
        primer_apellido: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        segundo_apellido: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        genero: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_nacimiento: {
          required: helpers.withMessage('El campo es requerido.', required),
        },

        nombre_calle: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
        },
        numero: {
          required: helpers.withMessage('El campo es requerido.', required),
        },

        tipo_identificacion: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_ingreso: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_comuna: {
          required: helpers.withMessage('El campo es requerido.', required),
        },

        correo: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener máximo 100 caracteres.', maxLength(100)),
          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
        celular: {
          maxLength: helpers.withMessage('Debe tener máximo 11 caracteres.', maxLength(11)),
          required: helpers.withMessage('El campo es requerido.', required),
        },

        id_cursos: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
      },
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>